import 'meteor'

import { lazy, Suspense } from 'react'
import * as ReactDOM from 'react-dom'

const App = lazy(() => import('school-legacy-front/ui/App'))
///
ReactDOM.render(
  <Suspense fallback='...'>
    <App />
  </Suspense>,
  document.getElementById('root') as HTMLElement,
)
